import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientXsrfModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { APP_BASE_HREF, PlatformLocation, registerLocaleData } from '@angular/common';

import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import localeNlNl from '@angular/common/locales/nl';
import localeNlNlExtra from '@angular/common/locales/extra/nl';
import localeDeDe from '@angular/common/locales/de';
import localeDeDeExtra from '@angular/common/locales/extra/de';
import localeEnGb from '@angular/common/locales/en-GB';
import localeEnGbExtra from '@angular/common/locales/extra/en-GB';
import '@progress/kendo-angular-intl/locales/nl/all';
import '@progress/kendo-angular-intl/locales/en/all';
import '@progress/kendo-angular-intl/locales/fr/all';
import '@progress/kendo-angular-intl/locales/de/all';
import '@progress/kendo-angular-intl/locales/es/all';

import { CustomHttpInterceptor } from './utils/custom-http-interceptor';
import { PipesModule } from './pipes/pipes.module';
import { HroCommonModule } from './components/hro-common.module';
import { AppInitializer } from './app.initializer';
import { AppRoutingModule } from './app.routing';
import { ApiService } from './services/api.service';
import { AuthenticationService } from './services/authentication.service';
import { HttpHelper,  QueryStringBuilder} from 'hro-helpers';
import { TranslationService } from './services/translation.service';
import { CurrentPageSettingsService } from './services/current-page-settings.service';
import { MessageService } from './services/message.service';
import { UserService } from './services/user.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { IntlModule } from '@progress/kendo-angular-intl';
import { RoutingStateService } from './services/routing-state.service';
import { GlobalStorageService } from './services/global-storage.service';
import { Broadcaster } from './utils/broadcaster';
import { FileHelperService } from './services/file-helper.service';

registerLocaleData(localeFr, 'fr-FR', localeFrExtra);
registerLocaleData(localeEs, 'es-ES', localeEsExtra);
registerLocaleData(localeEnGb, 'en-GB', localeEnGbExtra);
registerLocaleData(localeNlNl, 'nl-NL', localeNlNlExtra);
registerLocaleData(localeDeDe, 'de-DE', localeDeDeExtra);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientXsrfModule,
    FormsModule,
    HroCommonModule,
    AppRoutingModule,
    IntlModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    ApiService,
    AuthenticationService,
    AppInitializer,
    TranslationService,
    CurrentPageSettingsService,
    GlobalStorageService,
    QueryStringBuilder,
    MessageService,
    RoutingStateService,
    FileHelperService,
    UserService,
    Broadcaster,
    HttpHelper,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: ((ds: AppInitializer) => () => ds.load()),
      deps: [AppInitializer],
      multi: true
    },
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation]
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
