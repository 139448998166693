import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable()
export class CurrentPageSettingsService {
  private title = new BehaviorSubject<string>("");
  pageTitle$ = this.title.asObservable();

  page: PageEnum | null = null;

  setPage(page: PageEnum, title: string) {
    this.page = page;
    this.title.next(title);
  }

  clearPageData() {
    this.title.next("");
    this.page = null;
  }

  getArea(): PageAreaEnum {
    switch (this.page) {
      case PageEnum.CandidateOverview:
      case PageEnum.CandidateDetails:
      case PageEnum.CandidateEdit:
      case PageEnum.CandidateNew:
      case PageEnum.CandidateComments:
      case PageEnum.CandidateActivity: {
        return PageAreaEnum.Candidates;
      }
      case PageEnum.VacancyDetails:
      case PageEnum.VacancyOverview: {
        return PageAreaEnum.Vacancies;
      }
      case PageEnum.Dashboard: {
        return PageAreaEnum.Dashboard;
      }
      default: {
        return PageAreaEnum.None;
      }
    }
  }
}

export enum PageAreaEnum {
  None,
  Dashboard,
  Candidates,
  Vacancies,
  Settings,
}
export enum PageEnum {
  None,
  Dashboard,
  Login,
  ForgotPassword,
  Profile,

  CandidateOverview,
  CandidateDetails,
  CandidateEdit,
  CandidateNew,
  CandidateComments,
  CandidateActivity,

  VacancyOverview,
  VacancyDetails,

  Error,
}
