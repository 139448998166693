import { FileRestrictions } from "@progress/kendo-angular-upload";
import { Injectable } from "@angular/core";
//import sanitize = require("sanitize-filename");
//import * as sanitize from "sanitize-filename";
import { FileModel } from "../models/file.model";

@Injectable()
export class FileHelperService {
  sanitize = require("sanitize-filename");
  public maxFileSizeAllowedForDisplay = 10 * 1024 * 1024;

  companyCoverPhotoRules = {
    maxWidth: 3840,
    maxHeight: 2160,
    fileRestrictions: <FileRestrictions>{
      allowedExtensions: [".jpg", ".jpeg", ".gif", ".png"],
      minFileSize: 1,
      maxFileSize: 5 * 1024 * 1024
    }
  }

  candidateAttachmentRules = {
    fileNameMaxLength: 255,
    fileRestrictions: <FileRestrictions>{
      allowedExtensions: [".doc", ".docx", ".pdf", ".jpg", ".jpeg", ".gif", ".png"],
      minFileSize: 1,
      maxFileSize: 30 * 1024 * 1024
    }
  }

  companyLogoRules = {
    maxWidth: 400,
    maxHeight: 100,
    fileRestrictions: <FileRestrictions>{
      allowedExtensions: [".jpg", ".jpeg", ".gif", ".png"],
      minFileSize: 1,
      maxFileSize: 512 * 1024
    }
  }

  csvImportRules = {
    fileRestrictions: <FileRestrictions>{
      allowedExtensions: [".csv"],
      minFileSize: 1,
      maxFileSize: 30 * 1024 * 1024
    }
  }

  companyRouteRules = {
    fileRestrictions: <FileRestrictions>{
      allowedExtensions: [".pdf"],
      minFileSize: 1,
      maxFileSize: 10 * 1024 * 1024
    }
  }

  contactImageLogoRules = {
    maxWidth: 640,
    maxHeight: 640,
    fileRestrictions: <FileRestrictions>{
      allowedExtensions: [".jpg", ".jpeg", ".png"],
      minFileSize: 1,
      maxFileSize: 10 * 1024 * 1024
    }
  }

  vacancyFileAttachmentRules = {
    fileRestrictions: <FileRestrictions>{
      minFileSize: 1,
      maxFileSize: 10 * 1024 * 1024,
      allowedExtensions: [".doc", ".docx", ".pdf", ".txt"]
    }
  };

  candidatePictureRules = {
    fileRestrictions: <FileRestrictions>{
      allowedExtensions: [".jpg", ".jpeg", ".png"],
      minFileSize: 1,
      maxFileSize: 10 * 1024 * 1024
    }
  }

  noteAttachmentRules = {
    fileRestrictions: <FileRestrictions>{
      minFileSize: 1,
      maxFileSize: 10 * 1024 * 1024,
      allowedExtensions: [".jpg", ".png", ".gif", ".txt", ".doc", ".docx", ".rtf", ".pdf"]
    }
  };

  vacancyDocumentRules = {
    fileRestrictions: <FileRestrictions>{
      allowedExtensions: [".doc", ".docx", ".pdf"],
      minFileSize: 1,
      maxFileSize: 10 * 1024 * 1024
    }
  };

  getExtension(fileName: string | null): string | null {
    if (fileName == null)
      return null;

    let extension = "";

    let lastIndex = fileName.lastIndexOf(".");
    if (lastIndex !== -1)
      extension = fileName.substr(lastIndex + 1);

    return extension;
  }

  getSanitizedName(fileName: string | null, fileNameMaxLength: number = 255): string | null {

    if (fileName == null)
      return null;

    let sanitizedName: string, sanitizedExtension = "";
    let resultLength = 0;

    let extension = this.getExtension(fileName);
    let extensionPosition = fileName.lastIndexOf(".");


    if (extension) {
      sanitizedExtension = this.sanitize(extension);
      if (sanitizedExtension.length > 0)
        resultLength += sanitizedExtension.length + 1; // +1 for '.' separator
    }

    sanitizedName = this.sanitize(fileName.substr(0, extensionPosition));
    if (sanitizedName.length > 0)
      resultLength += sanitizedName.length;
    else
      return null;


    if (resultLength > fileNameMaxLength) {


      if (sanitizedExtension.length > 0)
        sanitizedName = sanitizedName.substr(0, fileNameMaxLength - sanitizedExtension.length);
      else
        sanitizedName = sanitizedName.substr(0, fileNameMaxLength);
    }
    let result = sanitizedName;
    if (sanitizedExtension.length > 0)
      result += `.${sanitizedExtension}`;

    return result;
  }

  public isImage(fileModel: FileModel) {
    let imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
    if (fileModel.extension == null)
      return false;

    return imageExtensions.indexOf(fileModel.extension.toLowerCase()) != -1;
  }

  blobToFile(theBlob: Blob, fileName: string): File {
    var b: any = theBlob;
    b.lastModifiedDate = new Date();
    b.name = fileName;
    return <File>b;
  }
}
