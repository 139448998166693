import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TemplateFormValidationComponent } from "./template-form-validation/template-form-validation.component";
import { TemplateFormCustomAsyncValidationDirective } from "./template-form-validation/template-form-custom-async-validation.directive";
import { TemplateFormCustomValidationDirective } from "./template-form-validation/template-form-custom-validation.directive";
import { IntegerDirective } from "./template-form-validation/integer.directive";
import { HroEmailDirective } from "./template-form-validation/hro-email.directive";
import { RegexDirective } from "./template-form-validation/regex.directive";
import { MinDirective } from "./template-form-validation/min.directive";
import { MaxDirective } from "./template-form-validation/max.directive";
import { GreaterThanEqualDirective } from "./template-form-validation/greater-than-equal.directive";
import { MinDateDirective } from "./template-form-validation/min-date.directive";
import { RequiredIfDirective } from "./template-form-validation/required-if.directive";
import { HroPasswordDirective } from "./template-form-validation/hro-password.directive";
import { HroKendoValidationErrors } from "./template-form-validation/hro-kendo-validation-errors.directive";
import { HroUrlDirective } from "./template-form-validation/hro-url.directive";
import { GreaterDirective } from "./template-form-validation/greater.directive";
import { DecimalDirective } from "./template-form-validation/decimal.directive";
import { MinDateTimeDirective } from "./template-form-validation/min-date-time.directive";
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        PipesModule
    ],
    declarations: [
        TemplateFormValidationComponent,
        TemplateFormCustomAsyncValidationDirective,
        TemplateFormCustomValidationDirective,
        IntegerDirective,
        DecimalDirective,
        RegexDirective,
        MinDirective,
        MaxDirective,
        GreaterThanEqualDirective,
        HroEmailDirective,
        MinDateDirective,
        RequiredIfDirective,
        HroPasswordDirective,
   	    HroUrlDirective,
        HroKendoValidationErrors,
        GreaterDirective,
        MinDateTimeDirective
    ],
    exports: [
        TemplateFormValidationComponent,
        TemplateFormCustomAsyncValidationDirective,
        TemplateFormCustomValidationDirective,
        IntegerDirective,
        DecimalDirective,
        RegexDirective,
        MinDirective,
        MaxDirective,
        HroUrlDirective,
        HroEmailDirective,
        GreaterThanEqualDirective,
        MinDateDirective,
        RequiredIfDirective,
        HroPasswordDirective,
        HroKendoValidationErrors,
        GreaterDirective,
        MinDateTimeDirective
    ]
})

export class ValidationsModule {

}
