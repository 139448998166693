export class GlobalStorageService {
    private storage: any = {};

    setValue(key: string, value: any) {
        this.storage[key] = value;
    }

    appendToValue(key: string, value: any) {
        if (this.storage[key] == null) {
            this.storage[key] = [value];
        } else {
            this.storage[key].push(value);
        }
    }

    removeFromArray(key: string, value: any) {
        const valueByKey = this.storage[key];
        if (valueByKey) {
            const index = valueByKey.indexOf(value);
            if (index !== -1)
                valueByKey.splice(index, 1);
        }
    }

    getValue(key: string): any {
        if (Array.isArray(this.storage[key]))
            return this.storage[key].slice();
        else
            return this.storage[key];
    }

    clear(key: string) {
        this.storage[key] = null;
    }

    clearAll() {
        Object.keys(this.storage).forEach(key => {
            this.storage[key] = null;
        });
    }
}
