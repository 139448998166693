import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpHelper } from 'hro-helpers';
import { LoginModel, LoginResultEnum } from '../pages/login/login.model';
import { Router } from '@angular/router';
import { TranslationService } from './translation.service';
import { Observer } from 'rxjs/internal/types';
import { UserService } from './user.service';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable()
export class AuthenticationService {

  constructor(private readonly httpHelper: HttpHelper,
    private readonly translationService: TranslationService,
    private readonly userService: UserService,
    private readonly apiService: ApiService,
    @Inject(LOCALE_ID) public locale: string,
    private readonly router: Router) {
  }
  forgotPassword(login: string): Observable<boolean> {
    return this.httpHelper.postSimple(login, this.apiService.authenticationUrl + '/forgot-password');
  }
  login(model: LoginModel): Observable<LoginResultEnum> {
    return this.httpHelper.post<LoginModel, LoginResultEnum>(model, this.apiService.authenticationUrl + '/login');
  }
  logout(): void {
    this.httpHelper.getObject<void>(this.apiService.authenticationUrl + '/logout').subscribe(() => {
      this.userService.clearUser();
      this.redirectToLoginPage(null);
    });
  }

  redirectToLoginPage(returnUrl: string | null): void {
    //this.userSubject.next(null);

    let loginRoutes = [this.locale + "/login"];
    if (!returnUrl) {
      this.router.navigate(loginRoutes);
      return;
    }

    this.router.navigate(loginRoutes,
      {
        queryParams: {
          returnurl: returnUrl
        }
      });
  }

  initLoader(): Observable<boolean> {
    return Observable.create((observer: Observer<boolean>) => {
      this.translationService.fillTranslations().subscribe(() => {
        this.isAuthenticated().subscribe((data) => {
          if (data === true) {
            observer.next(data);
            //TODO: uncomment
            //this.getCurrentUser().subscribe(() => observer.next(data));
          } else {
            observer.next(data);
          }
        });
      });
    });
  }

  isAuthenticated(): Observable<boolean> {
    return this.httpHelper.get(this.apiService.authenticationUrl + "/is-authenticated");
  }
}
