import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { combineLatest } from "rxjs";
import { AuthenticationService } from "./services/authentication.service";
import { TranslationService } from "./services/translation.service";
import { UserService } from "./services/user.service";

@Injectable()
export class AppInitializer {

  constructor(private readonly authenticationService: AuthenticationService,
    private readonly translationService: TranslationService,
    private readonly userService: UserService,
    @Inject(LOCALE_ID) private locale: string) {

  }

  load(): Promise<boolean> {
    return new Promise((resolve) => {
      combineLatest(this.translationService.fillTranslations(),
        this.authenticationService.isAuthenticated())
        .subscribe((data) => {
          if (data[1]) {
            this.userService.getCurrentUser().subscribe((usr) => {
              resolve(true);
            });
          }
          else {
            resolve(true);
          }
        });
    });
  }
}
