import { inject } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { CanMatchFn, Route, UrlSegment } from '@angular/router';
import { APP_BASE_HREF } from '@angular/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class LoggedInGuard {

  static canMatch: CanMatchFn =(route: Route, segments: UrlSegment[]): Observable<boolean> => {
    const authenticationService = inject(AuthenticationService);
    const baseHref = inject(APP_BASE_HREF);
    return authenticationService.isAuthenticated().pipe(
      map((data) => {
        if (data === false) {
          let fullPath = segments.reduce((path, currentSegment) => {
            return `${path}/${currentSegment.path}`;
          }, baseHref.substring(0, baseHref.length - 1));
          authenticationService.redirectToLoginPage(fullPath + window.location.search);
          return false;
        }
        return true;
      }));
  }
}
