import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from '@angular/common';
import { SafePipe } from './safe.pipe';
import { HroTranslatePipe } from './hro-translate.pipe';
import { UniqueNamePipe } from "./unique-name.pipe";
import { JoinPipe } from "./join.pipe";
import { AbbrPipe } from "./abbr-pipe";

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    SafePipe,
    HroTranslatePipe,
    UniqueNamePipe,
    JoinPipe,
    AbbrPipe,
  ],
  exports: [
    SafePipe,
    HroTranslatePipe,
    UniqueNamePipe,
    JoinPipe,
    AbbrPipe,
  ],
  providers: [
    DatePipe
  ]
})

export class PipesModule {
}
