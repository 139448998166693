import { Component, HostListener } from "@angular/core";
import { SimpleModalComponent } from "ngx-simple-modal";
import { TranslationService } from "../../../services/translation.service";
import { TranslationInstances } from '../../../global';

export interface IDropDownDialogModel {
    title: string | undefined;
    value: any;
    data: any[];
    textField: string;
    valueField: string;
    bodyText: string | undefined;
    isRequired: boolean;
    defaultItem: any;
}

@Component({
    selector: "dropdown-dialog",
    templateUrl: "./dropdown-dialog.html",
})

export class DropDownDialogComponent extends SimpleModalComponent<IDropDownDialogModel, any> implements IDropDownDialogModel {
    title: string | undefined;
    value: any;
    data: any[] = [];
    textField!: string;
    valueField!: string;
    bodyText: string | undefined;
    isRequired = false;
    defaultItem: any = null;

    readonly translationInstances = TranslationInstances;
   // readonly dropDownFilterSettings = DropDownFilterOptions.options;

    constructor(private readonly translationService: TranslationService) {
        super();
    }

    ngOnInit() {
        if (!this.defaultItem) {
            this.defaultItem = {
                id: null,
                name: this.translationService.translate(this.translationInstances.globals, "Select value")
            };
        }
    }

    apply() {
        this.result = this.value;
        this.close();
    }

    @HostListener("document:keydown.escape")
    onEsc() {
        this.result = -1;
        this.close();
    }

    onCloseClick() {
        this.result = -1;
        this.close();
    }
}
