import { NgModule } from '@angular/core';
import { Routes, RouterModule, NoPreloading, UrlSegment } from '@angular/router';
import { LoggedInGuard } from './guards/logged-in.guard';


export function publicArea(url: UrlSegment[]) {

  if (url.length == 0) {
    return null;
  }

  let isPublicPage = url[0].path === "en" ||
    url[0].path === "fr" ||
    url[0].path === "de" ||
    url[0].path === "es" ||
    url[0].path === "nl" || url[0].path === "login";

  return isPublicPage ? { consumed: [new UrlSegment(url[0].path, url[0].parameters)], posParams: { language: url[0] } } : null
}
const routes: Routes = [
  {
    matcher: publicArea,
    loadChildren: () => import("./pages/areas/public-area/public-area.module").then(m => m.PublicAreaModule),
  },
  {
    path: "errors",
    loadChildren: () => import("./pages/areas/errors-area/errors-area.module").then(m => m.ErrorsAreaModule)
  },
  {
    path: "",
    canMatch: [LoggedInGuard.canMatch],
    loadChildren: () => import("./pages/areas/private-area/private-area.module").then(m => m.PrivateAreaModule)
  },
  {
    path: '**',

    redirectTo: '/errors/404'
  }
];

@NgModule({
    imports: [RouterModule.forRoot(routes,
        {
            preloadingStrategy: NoPreloading, //PreloadAllModules,
            //enableTracing: true// <-- debugging purposes only);

        } )],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
