import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import { String } from 'typescript-string-operations';

class ApiUrl {
  private static readonly prefix = "/api/{0}";

  static readonly languagesUrl = ApiUrl.prefix + "/languages";
  static readonly campaignsUrl = ApiUrl.prefix + "/campaigns";
  static readonly settingsUrl = ApiUrl.prefix + "/settings";
  static readonly authenticationUrl = ApiUrl.prefix + "/authentication";
  static readonly brandSettingsUrl = ApiUrl.prefix + "/brandsettings";
  static readonly vacanciesUrl = ApiUrl.prefix + "/vacancies";
  static readonly candidatesUrl = ApiUrl.prefix + "/candidates";
  static readonly registrationUrl = ApiUrl.prefix + "/registration";
  static readonly countriesUrl = ApiUrl.prefix + "/countries";
  static readonly customerUsersUrl = ApiUrl.prefix + "/customer-users";
  static readonly usersUrl = ApiUrl.prefix + "/users";
  static readonly jobboardsUrl = ApiUrl.prefix + "/{1}/jobboards";
  static readonly shoppingCartUrl = ApiUrl.prefix + "/{1}/shoppingCart";
  static readonly educationLevelUrl = ApiUrl.prefix + "/{1}/educationLevels";
  static readonly channelTypesUrl = ApiUrl.prefix + "/channelTypes";
  static readonly provincesUrl = ApiUrl.prefix + "/provinces";
  static readonly provincesWithCountryUrl = ApiUrl.prefix + "/{1}/provinces";
  static readonly functionsUrl = ApiUrl.prefix + "/{1}/functions";
  static readonly suggestionUrl = ApiUrl.prefix + "/{1}/suggestion";
  static readonly askAdvice = ApiUrl.prefix + "/askAdvice";
  static readonly ordersUrl = ApiUrl.prefix + "/orders";
}

@Injectable()
export class ApiService {

  constructor(@Inject(LOCALE_ID) public locale: string) {
  }

  private getReplacedUrl(url: string): string {
    return String.Format(url, `${this.locale}`)
  }

  private getReplacedUrlWithCountry(url: string): string {
    return String.Format(url, `${this.locale}`, `{0}`)
  }

  public get authenticationUrl(): string {
    return this.getReplacedUrl(ApiUrl.authenticationUrl);
  }

  public get vacanciesUrl(): string {
    return this.getReplacedUrl(ApiUrl.vacanciesUrl);
  }

  public get languagesUrl(): string {
    return this.getReplacedUrl(ApiUrl.languagesUrl);
  }

  public get countriesUrl(): string {
    return this.getReplacedUrl(ApiUrl.countriesUrl);
  }

  public get customerUsersUrl(): string {
    return this.getReplacedUrl(ApiUrl.customerUsersUrl);
  }

  public get usersUrl(): string {
    return this.getReplacedUrl(ApiUrl.usersUrl);
  }

  public get registrationUrl(): string {
    return this.getReplacedUrl(ApiUrl.registrationUrl);
  }

  public get campaignsUrl(): string {
    return this.getReplacedUrl(ApiUrl.campaignsUrl);
  }

  public get settingsUrl(): string {
    return this.getReplacedUrl(ApiUrl.settingsUrl);
  }

  public get brandSettingsUrl(): string {
    return this.getReplacedUrl(ApiUrl.brandSettingsUrl);
  }

  public get jobboardsUrl(): string {
    return this.getReplacedUrlWithCountry(ApiUrl.jobboardsUrl);
  }

  public get candidatesUrl(): string {
    return this.getReplacedUrl(ApiUrl.candidatesUrl);
  }

  public get shoppingCartUrl(): string {
    return this.getReplacedUrlWithCountry(ApiUrl.shoppingCartUrl);
  }

  public get educationLevelUrl(): string {
    return this.getReplacedUrlWithCountry(ApiUrl.educationLevelUrl);
  }

  public get functionsUrl(): string {
    return this.getReplacedUrlWithCountry(ApiUrl.functionsUrl);
  }

  public get channelTypesUrl(): string {
    return this.getReplacedUrl(ApiUrl.channelTypesUrl);
  }

  public get provincesWithCountryUrl(): string {
    return this.getReplacedUrlWithCountry(ApiUrl.provincesWithCountryUrl);
  }

  public get provincesUrl(): string {
    return this.getReplacedUrl(ApiUrl.provincesUrl);
  }

  public get suggestionUrl(): string {
    return this.getReplacedUrlWithCountry(ApiUrl.suggestionUrl);
  }

  public get askAdviceUrl(): string {
    return this.getReplacedUrl(ApiUrl.askAdvice);
  }

  public get ordersUrl(): string {
    return this.getReplacedUrl(ApiUrl.ordersUrl);
  }
}
