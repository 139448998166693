<ul *ngIf="errorMessages" class="validation-errors-list">
        <ng-container *ngFor="let errorMessage of errorMessages">
            <ng-container [ngSwitch]="errorMessage.key">
                <li *ngSwitchCase="'required'">{{'This field is required' | hroTranslate: translationInstances.validationErrors}}</li>
                <li *ngSwitchCase="'minError'">{{'Minimum date is' | hroTranslate: translationInstances.validationErrors}} {{errorMessage.value.minValue | date: 'dd-MM-yyyy'}}</li><!--minError error for kendo datepicker for min date-->
                <li *ngSwitchCase="'maxError'">{{'Maximum date is' | hroTranslate: translationInstances.validationErrors}} {{errorMessage.value.maxValue | date: 'dd-MM-yyyy'}}</li><!--maxError error for kendo datepicker for max date-->
                <li *ngSwitchCase="'timeRangeError'">{{'Minimum time is' | hroTranslate: translationInstances.validationErrors}} {{errorMessage.value.minValue | date: 'HH:mm'}}</li>
                <li *ngSwitchCase="'minlength'">{{'Minimum length' | hroTranslate: translationInstances.validationErrors}} {{errorMessage.value.requiredLength}}</li>
                <li *ngSwitchCase="'maxlength'">{{'Maximum length' | hroTranslate: translationInstances.validationErrors}} {{errorMessage.value.requiredLength}}</li>
                <li *ngSwitchCase="'max'">{{'Maximum value is' | hroTranslate: translationInstances.validationErrors}} {{errorMessage.value.max}}</li>
                <li *ngSwitchCase="'min'">{{'Minimum value is' | hroTranslate: translationInstances.validationErrors}} {{errorMessage.value.min}}</li>
                <li *ngSwitchCase="'email'">{{'Invalid email address' | hroTranslate: translationInstances.validationErrors}}</li>
                <li *ngSwitchCase="'integer'">{{'Integer value is required' | hroTranslate: translationInstances.validationErrors}}</li>
                <li *ngSwitchCase="'password'">{{'Password must contains at least a special character, uppercase letter and a digit and must contain at least 8 characters.' | hroTranslate: translationInstances.validationErrors}}</li>
                <li *ngSwitchCase="'url'">{{'Invalid URL address' | hroTranslate: translationInstances.validationErrors}}</li>
                <li *ngSwitchCase="'common'">{{errorMessage.value}}</li>
                <li *ngSwitchCase="'mustMatch'">{{errorMessage.value}}</li>
                <li *ngSwitchCase="'regex'">{{errorMessage.value}}</li>
                <li *ngSwitchCase="'decimal'">{{errorMessage.value}}</li>
                <li *ngSwitchCase="'minDate'">{{'Date should be more than'}} {{errorMessage.value.value.toLocaleDateString()}}</li>
                <li *ngSwitchCase="'gte'">{{'Value must be greater or equal than' | hroTranslate: translationInstances.validationErrors}} {{errorMessage.value.value}}</li>
                <li *ngSwitchCase="'gt'">{{'Value must be greater than' | hroTranslate: translationInstances.validationErrors}} {{errorMessage.value.value}}</li>
                <li *ngSwitchCase="'minDateTime'">{{'Date should be more than'}} {{errorMessage.value.value}}</li>
                <ng-container *ngSwitchCase="'kendoValidationErrors'">
                    <li *ngFor="let error of errorMessage.value">
                        {{error}}
                    </li>
                </ng-container>
                <li *ngSwitchDefault>{{'Invalid value' | hroTranslate: translationInstances.validationErrors}}</li>
            </ng-container>
        </ng-container>
    </ul>
