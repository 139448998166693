import { Injectable } from "@angular/core";
import { HttpHelper } from "hro-helpers";
import { HtmlHelper } from '../utils/htmlHelper';
import { LanguageModel } from '../models/language.model';
import { ApiService } from './api.service';
import { Observable, Observer } from "rxjs";


export class TranslationItem {
    instance!: string;
    key!: string;
    value!: string;
}

@Injectable()
export class TranslationService {
    private translations: TranslationItem[] = [];

    constructor(readonly httpHelper: HttpHelper, private readonly apiService: ApiService) {
    }

    fillTranslations(): Observable<boolean> {
        return Observable.create((observer: Observer<boolean>) => {
            this.httpHelper.getObject<TranslationItem[]>(this.apiService.languagesUrl + "/translations", null)
                .subscribe((data) => {
                        this.translations = data || [];
                        observer.next(true);
                    },
                    err => observer.error(err));
        });

    }

    translate(instance: string, key: string): string {
        if (instance && key) {
            const translation =
                this.translations.find(item => item.instance.toLocaleLowerCase() === instance.toLocaleLowerCase() &&
                    item.key.trim().toLocaleLowerCase() === key.trim().toLocaleLowerCase());
            if (translation)
                return HtmlHelper.decodeHtmlEntity(translation.value);
        }

        console.error(
            `Translation wasn't found for KEY: ${key} in ${instance} | AddTranslation(TranslationInstances.${instance
            }, "${key}");`);
        return `[${key}]`;
    }

    hasKey(instance: string, key: string): boolean {
      if (this.translations && instance && key) {
        return this.translations.find(item => item.instance.toLocaleLowerCase() === instance.toLocaleLowerCase() &&
          item.key.trim().toLocaleLowerCase() === key.trim().toLocaleLowerCase()) !=
          null;
      }
      else {
        return false;
      }
    }

    changeCurrentLanguage(languageCode: string) {
        return this.httpHelper.post<null, void>(null,
            this.apiService.languagesUrl + "/change?languageCode=" + languageCode);
    }

    getLanguages(): Observable<LanguageModel[]> {
        return this.httpHelper.get(this.apiService.languagesUrl);
    }

    getSelfTranslatedLanguages(): Observable<LanguageModel[]> {
      return this.httpHelper.get(this.apiService.languagesUrl + "/self-translated");
    }

    getCurrentLanguage(): Observable<LanguageModel> {
        return this.httpHelper.get(this.apiService.languagesUrl + "/current");
    }
}

