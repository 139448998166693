import { Router } from '@angular/router';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { TranslationService } from '../services/translation.service';
import { CustomHttpParams } from 'hro-helpers';
import { TranslationInstances } from '../global';
import { MessageService } from '../services/message.service';
import { String } from 'typescript-string-operations';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserService } from '../services/user.service';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

  private hideErrorMessages = false;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
    readonly userService: UserService,
    private readonly translationService: TranslationService,
    private readonly msgService: MessageService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.headers.has("Cache-Control")) {
      request.headers.append("Cache-Control", "no-cache");
    }
    if (!request.headers.has("Pragma")) {
      request.headers.append("Pragma", "no-cache");
    }

    if (request.params instanceof CustomHttpParams) {
      const customParams = (request.params as CustomHttpParams);
      if (customParams.data) {
        this.hideErrorMessages = customParams.data.hideErrorMessages;
      }
    }
    let languageRequest = request.clone({ setHeaders: { 'Accept-Language': "en" } });

    const requestMethod = request.method.toLowerCase();

    return next.handle(languageRequest).pipe(catchError((error) => {

      const rethrow = this.handleServerError(error, requestMethod);

      if (rethrow) {
        return throwError(error);
      }
      else {
        return of(error);
      }

    }) as any);
  }

  private handleServerError(error: HttpErrorResponse, requestMethod: string): boolean {
    if (error.status === 401) {
      this.userService.clearUser();
      const localRoute = window.location.pathname + window.location.search + window.location.hash;
      this.authenticationService.redirectToLoginPage(localRoute);
      return false;
    }

    if (error.status === 403) {

      let currentUserLogin = this.userService.isUserExists() ? this.userService.userValue.login : null;
      this.userService.clearUser();
      this.userService.getCurrentUser().subscribe(y => {
        let forceReload = y.login != currentUserLogin;
        if (forceReload) {
          window.location.href = '/errors/403';
        } else {
          if (error.status === 403 && requestMethod === "get") {
            this.router.navigate(["/errors/403"]);
          }

          if (error.status === 403 && requestMethod !== "get") {
            this.showUserFrendlyMessage(error.error);
          }
        }
      });

      if (requestMethod !== "get")
        return true;
      else
        return false;
    }

    if (error.status === 404 && requestMethod === "get") {
      this.router.navigate(["/errors/404"]);
      return false;
    }

    if (error.status === 404 && requestMethod !== "get") {
      this.showUserFrendlyMessage(error.error);
      return true;
    }

    if (error.status === 400) {
      this.showUserFrendlyMessage(error.error);
      return true;
    }

    if (error.status === 500) {
      this.showUserFrendlyMessage(error.error);
      return true;
    }

    return true;
  }

  private showUserFrendlyMessage(model: any) {
    if (this.hideErrorMessages) {
      return;
    }

    const translation = this.createUserFrendlyMessage(model);

    if (model.errorModels != null && model.errorModels.length > 0) {
      let message = translation;
      message = message.concat("<ol>");
      model.errorModels.forEach((x: any) => {
        let msg = this.createUserFrendlyMessage(x);
        message = message.concat('<li>' + msg + "</li>");
      });
      message = message.concat("</ol>");

      this.msgService.notificationInfo(
        this.translationService.translate(TranslationInstances.globals, "Alert"),
        message
      );
    } else {
      this.msgService.notificationError(translation, this.translationService.translate(TranslationInstances.globals, "Error"));
    }
  }

  private createUserFrendlyMessage(model: any): string {
    let translation;
    if (model.translationKey == null) {
      return model.message;
    }

    translation = this.translationService.translate(TranslationInstances.exceptions, model.translationKey);

    if (model.translationSubstitutions != null && model.translationSubstitutions.length > 0) {
      translation = String.Format(translation, model.translationSubstitutions);
    }

    if (model.wrappedError != null) {
      const innerMessage = this.createUserFrendlyMessage(model.wrappedError);
      translation = translation + " " + innerMessage;
    }

    return translation;
  }
}
