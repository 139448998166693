import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { TranslateDirective } from "./translate.directive";
import { CheckDdlValueDirective } from './check-ddl-value.directive';
import { SlimScroll } from "./slimscroll.directive";
import { SubmenuItemDirective } from "./submenu-item.directive";
import { PageAreaDirective } from "./page-area.directive";
import { AriaDescribedByDirective } from "./aria-describedby.directive";
import { LabelForDirective } from "./label-for.directive";
import { HroKendoWcagDirective } from "./hro-kendo-wcag.directive";

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    TranslateDirective,
    CheckDdlValueDirective,
    SlimScroll,
    SubmenuItemDirective,
    PageAreaDirective,
    AriaDescribedByDirective,
    LabelForDirective,
    HroKendoWcagDirective
  ],
  exports: [
    TranslateDirective,
    CheckDdlValueDirective,
    SlimScroll,
    SubmenuItemDirective,
    PageAreaDirective,
    AriaDescribedByDirective,
    LabelForDirective,
    HroKendoWcagDirective
  ]
})

export class DirectivesModule {

}
