import { Injectable } from "@angular/core";
import { Router, NavigationStart, RouterEvent, Event } from "@angular/router";
import { filter } from "rxjs/operators";

@Injectable()
export class RoutingStateService {

    private history: string[] = [];

    constructor(private router: Router) {
    }

    subscribe() {
        this.router.events.pipe(
            filter((event: Event, index: number) => event instanceof NavigationStart))
          .subscribe((event: Event) => {
            let evnt = event as any as NavigationStart;
            this.history = [...this.history, evnt.url];
            });
    }

    getHistory(): string[] {
        return this.history;
    }

    getPreviousUrl(): string {
        return this.history[this.history.length - 2];
    }

    getBackOrToUrl(defaultUrl: string) {
        let prevUrl = this.getPreviousUrl();
        if (prevUrl) {
            this.router.navigateByUrl(prevUrl);
        } else {
            this.router.navigateByUrl(defaultUrl);
        }
    }

}
