import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'app';

  ngOnInit() {
    const loadingBox = document.getElementById("loading-box");
    if (loadingBox && loadingBox.parentNode) {
      loadingBox.parentNode.removeChild(loadingBox);
    }
  }
}
