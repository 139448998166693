<div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="block block-rounded block-transparent mb-0">
        <div class="block-header block-header-default">
          <h4 class="block-title">{{title || ('Confirm' | hroTranslate: translationInstances.globals)}}</h4>
          <a (click)="close()"><i class="fa fa-close"></i></a>
        </div>
        <div class="block-content">
          <p>{{message || ('Are you sure?' | hroTranslate: translationInstances.globals)}}</p>
        </div>
        <div class="block-content block-content-full block-content-sm text-end bg-body">
          <button type="button" class="btn btn-alt-primary btn-sm me-1" (click)="confirm()">{{'OK' | hroTranslate: translationInstances.globals}}</button>
          <button type="button" class="btn btn-alt-secondary btn-sm" (click)="close()">{{'Cancel' | hroTranslate: translationInstances.globals}}</button>
        </div>
      </div>
    </div>
</div>
