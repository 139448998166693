//import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";

export class GlobalConstants {
  static readonly defaultLanguageCode = "en";
  static readonly VALIDATION_PREFIX = "_validation";
}

export class DataPatterns {
  static readonly defaultUrlDateFormat = "YYYY-MM-DD";
}

export class LoadingTemplates {
  static readonly formHorizontal4x8 = "formHorizontal4x8";
  static readonly defaultLoader = "defaultLoader";
}

export class TranslationInstances {
  static readonly globals = "Globals";
  static readonly dashboard = "Dashboard";
  static readonly vacancySummary = "VacancySummary";
  static readonly vacancyOverview = "VacancyOverview";
  static readonly vacancyDetails = "VacancyDetails";

  static readonly candidateSummary = "CandidateSummary";
  static readonly candidateOverview = "CandidateOverview";
  static readonly candidateDetails = "CandidateDetails";
  static readonly candidateLeftMenu = "CandidateLeftMenu";

  static readonly candidateNew = "CandidateNew";
  static readonly vacancyLeftMenu = "VacancyLeftMenu";
  static readonly changePassword = "ChangePassword";
  static readonly sidebar = "Sidebar";
  static readonly header = "Header";
  static readonly login = "Login";
  static readonly validationErrors = "ValidationErrors";
  static readonly exceptions = "Exceptions";
  static readonly notifications = "Notifications";
  static readonly userProfile = "UserProfile";
}

export class DropDownFilterOptions {
    static options: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains'
    };
}

export class ErrorReasons {
  static readonly InvalidToken = "InvalidToken";
}
