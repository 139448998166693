<div class="modal-dialog" role="document">
  <form class="modal-content" #form="ngForm" (ngSubmit)="form.valid && apply()">
    <div class="block block-rounded block-transparent mb-0">
      <div class="block-header block-header-default">
        <h3 class="block-title">{{title}}</h3>
        <div class="block-options">
          <button type="button" class="btn-block-option" (click)="close()"><i class="fa fa-fw fa-times"></i></button>
        </div>
      </div>
      <div class="block-content">
          <div class="alert alert-warning push" *ngIf="bodyText">
              {{bodyText}}
          </div>
          <kendo-dropdownlist [data]="data"
                              [textField]="textField"
                              [valueField]="valueField"
                              [(ngModel)]="value"
                              class="w-100"
                              [defaultItem]="defaultItem"
                              name="value"
                              #valueControl="ngModel"
                              [required]="isRequired"></kendo-dropdownlist>
          <hro-template-form-validation [control]="valueControl"></hro-template-form-validation>
      </div>
      <div class="block-content block-content-full block-content-sm text-end border-top bg-body">
          <button type="submit" class="btn btn-sm button-alt-primary">{{'OK' | hroTranslate: translationInstances.globals}}</button>
          <button type="button" class="btn btn-sm btn-alt-secondary ms-1" (click)="onCloseClick()">{{'Cancel' | hroTranslate: translationInstances.globals}}</button>
      </div>
    </div>
  </form>
</div>

