import { Injectable } from "@angular/core";
import { ToastrService } from 'ngx-toastr';
import { SimpleModalService } from 'ngx-simple-modal';
//import { CustomerUserModel } from "../models/customer-user.model";
//import { CustomerUserSelectorDialogComponent } from "../components/customer-user/selector/dialog/customer-user-selector-dialog.component";
import { ConfirmDialogComponent } from "../components/popups/confirm-dialog/confirm-dialog.component";
import { DropDownDialogComponent } from "../components/popups/dropdown-dialog/dropdown-dialog.component";

@Injectable()
export class MessageService {

  constructor(private toastr: ToastrService,
    private readonly simpleModalService: SimpleModalService) { }

    notificationError(message: string, title?: string) {
      this.toastr.error(message, title, { closeButton: true, positionClass: "toast-bottom-left" });
    }

    notificationSuccess(message: string, title?: string) {
      this.toastr.success(message, title, { closeButton: true, positionClass:"toast-bottom-left" });
    }

    notificationInfo(message: string, title?: string) {
      this.toastr.info(message, title, { closeButton: true, positionClass: "toast-bottom-left" });
  }

    dropdownPrompt(title: string,
        data: any[],
        value: any,
        valueField: string,
        textField: string,
        applyCallback: Function,
        bodyText: string | undefined = undefined,
        isRequired = true,
        defaultItem = null) {
        this.simpleModalService.addModal(DropDownDialogComponent,
            {
                data: data,
                value: value,
                textField: textField,
                valueField: valueField,
                title: title,
                bodyText: bodyText,
                isRequired: isRequired,
                defaultItem: defaultItem
            }
        ).subscribe((value: any) => {
            if (value != -1) {
                applyCallback(value);
            }
        });
    }

    confirmationDialog(title: string | null | undefined, message: string, confirm: Function, cancel: Function | null): void {
        this.simpleModalService.addModal(ConfirmDialogComponent, {
                title: title,
                message: message
            })
            .subscribe((isConfirmed: boolean) => {
                if (isConfirmed) {
                    if (typeof confirm == "function") {
                        confirm();
                    }
                } else {
                    if (typeof cancel == "function") {
                        cancel();
                    }
                }
            });
    }

    //customerUserPrompt(title: string,
    //    data: CustomerUserModel[],
    //    value: CustomerUserModel | number,
    //    valuePrimitive: boolean,
    //    applyCallback: Function,
    //    bodyText: string | undefined = undefined) {
    //    this.simpleModalService.addModal(CustomerUserSelectorDialogComponent,
    //        { data: data, value: value, title: title, valuePrimitive: valuePrimitive, bodyText: bodyText }
    //    ).subscribe((value: any) => {
    //        if (value) {
    //            applyCallback(value);
    //        }
    //    });
    //}
}
