import { Component, HostListener } from "@angular/core";
import { SimpleModalComponent } from "ngx-simple-modal";
import { TranslationInstances } from '../../../global';

export interface ConfirmDialogModel {
  title: string | undefined | null;
  message: string | undefined | null;
}

@Component({
  selector: "hro-confirm-dialog",
  templateUrl: "./confirm-dialog.html",
  styleUrls: ["./style.less"]
})
export class ConfirmDialogComponent extends SimpleModalComponent<ConfirmDialogModel, boolean> implements
  ConfirmDialogModel {
  title: string | undefined | null;
  message: string | undefined | null;
  readonly translationInstances = TranslationInstances;

  constructor() {
    super();
  }

  confirm() {
    this.result = true;
    this.close();
  }

  @HostListener("document:keydown.escape")
  onEsc() {
    this.close();
  }
}
