import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { SimpleModalModule } from "ngx-simple-modal";

import { ValidationsModule } from './validation/validations.module';
import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';
//import { IntlModule } from '@progress/kendo-angular-intl';
//import { GridModule } from '@progress/kendo-angular-grid';
import { ConfirmDialogComponent } from './popups/confirm-dialog/confirm-dialog.component';
import { DropDownDialogComponent } from './popups/dropdown-dialog/dropdown-dialog.component';
import { LoadingWrapperComponent } from './loading-wrapper/loading-wrapper.component';
import { FileUploadModule } from './file-upload/file-upload.module';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { HroHelpersModule } from "hro-helpers";

@NgModule({
    imports: [
        RouterModule,
        FormsModule,
        CommonModule,
        ValidationsModule,
        PipesModule,
        DirectivesModule,
        SimpleModalModule,
        DropDownsModule,
        DateInputsModule,
        //IntlModule,
        TooltipModule,
        //GridModule,
        FileUploadModule,
        HroHelpersModule
    ],
    declarations: [
        ConfirmDialogComponent,
        DropDownDialogComponent,
        LoadingWrapperComponent
    ],
    exports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ValidationsModule,
        PipesModule,
        //IntlModule,
        DirectivesModule,
        DropDownsModule,
        DropDownsModule,
        DateInputsModule,
        TooltipModule,
        //GridModule,
        FileUploadModule,
        ConfirmDialogComponent,
        DropDownDialogComponent,
        LoadingWrapperComponent,
        HroHelpersModule
    ]
})
export class HroCommonModule { }

