import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { UploadModule } from "@progress/kendo-angular-upload";
import { ImageCropperModule } from "ngx-image-cropper";
import { DirectivesModule } from "../../directives/directives.module";
import { PipesModule } from "../../pipes/pipes.module";

import { ValidationsModule } from "../validation/validations.module";
import { FileUploadComponent } from "./file-upload.component";
import { ImageUploadComponent } from "./image/image-upload.component";

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ValidationsModule,
    PipesModule,
    DirectivesModule,
    ImageCropperModule,
    UploadModule
  ],
  declarations: [
    FileUploadComponent,
    ImageUploadComponent
  ],
  exports: [
    FileUploadComponent,
    ImageUploadComponent
  ]
})

export class FileUploadModule {

}
