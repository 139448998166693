import { Pipe, PipeTransform } from "@angular/core";
import { TranslationService } from "../services/translation.service";

@Pipe({
    name: "hroTranslate"
})
export class HroTranslatePipe implements PipeTransform {

    constructor(private readonly translationService: TranslationService) {

    }
    transform(key: string, instance: string): string {
        if (!key) return "";

        const translation = this.translationService.translate(instance, key);
        return translation;
    }
}
