import { Component, OnInit, Input, ElementRef, Optional, SkipSelf } from "@angular/core";
import { NgModel, NgForm, ControlContainer } from '@angular/forms';
import { TranslationInstances } from '../../../global';
import { KeyValuePair } from 'hro-helpers';


@Component({
    selector: "hro-template-form-validation",
    templateUrl: "./template-form-validation.html",
    styleUrls: ["./style.less"],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class TemplateFormValidationComponent implements OnInit {
    @Input()
    control!: NgModel;
    private form: NgForm;

    private errorClassName = "is-invalid";
    private validators: any;
    private asyncValidators: any;

    readonly translationInstances = TranslationInstances;

    private divElement: any;
    private inputElement: any;

    constructor(
        @Optional() @SkipSelf() form: NgForm,
        private elRef: ElementRef) {
        this.form = form;
    }

    ngOnInit() {
        if (!this.control) {
            throw new Error("control is empty");
        }
    }

    ngAfterViewInit() {

        this.divElement = this.elRef.nativeElement.parentElement;
        if (this.divElement != null) {
            this.inputElement = this.divElement.querySelector("input:not([type=\"checkbox\"])");
        }

        if (this.inputElement) {
            this.inputElement.addEventListener("click",
                () => {
                    this.clearValidation();
                });
            this.inputElement.addEventListener("keydown",
                (target: any) => {
                    if (target.keyCode == 13) {
                        this.restoreValidation(target);
                    }
                });
        }

        if (this.divElement) {
            this.divElement.addEventListener("keyup.tab",
                () => {
                    this.clearValidation();
                });

            this.divElement.addEventListener("focusout",
                (target: any) => {
                    this.restoreValidation(target);
                });
        }
    }

    private restoreValidation(target: any) {
        if (target.type !== "checkbox") {
            if (this.asyncValidators) {
                this.control.control.setAsyncValidators(this.asyncValidators);
            }
            if (this.validators) {
                this.control.control.setValidators(this.validators);
            }
            this.control.control.updateValueAndValidity();
        }

        this.validate();
    }

    private clearValidation() {
        if (this.inputElement) {
            this.inputElement.classList.remove(this.errorClassName);
        }

        if (this.divElement) {
            this.divElement.classList.remove("has-error");
        }

        if (this.control.control.validator) {
            this.validators = this.control.control.validator;
            this.control.control.clearValidators();
        }

        if (this.control.control.asyncValidator) {
            this.asyncValidators = this.control.control.asyncValidator;
            this.control.control.clearAsyncValidators();
        }

        if (this.control.value == null) {
            this.control.reset();
        }

    }

    ngAfterContentChecked() {
        this.validate();
    }

    private validate() {
        if (this.control.errors != null && (this.control.touched || this.form.submitted)) {
            if (this.divElement) {
                this.divElement.classList.add("has-error");
            }
            if (this.inputElement) {
                this.inputElement.classList.add(this.errorClassName);
            }
        } else {
            if (this.divElement) {
                this.divElement.classList.remove("has-error");
            }
            if (this.inputElement) {
                this.inputElement.classList.remove(this.errorClassName);
            }
        }
    }

  protected get errorMessages(): KeyValuePair<string, any>[] | undefined {
    if (this.control.errors && (this.control.touched || this.form.submitted)) {
      return Object.getOwnPropertyNames(this.control.control.errors).map(x => new KeyValuePair(x, this.control.errors![x]));
    }
    return undefined;
  }
}
