import { Observable, Subject } from "rxjs";
import { filter, map } from "rxjs/operators";

interface BroadcastEvent {
    key: GlobalEvents;
    data?: any;
}

export class Broadcaster {
    private _eventBus: Subject<BroadcastEvent>;

    constructor() {
        this._eventBus = new Subject<BroadcastEvent>();
    }

    broadcast(key: any, data?: any) {
        this._eventBus.next({ key, data });
    }

    on<T>(key: GlobalEvents): Observable<T> {
        return this._eventBus.asObservable().pipe(
            filter((event: BroadcastEvent) => event.key === key || (event.key != key && event.key.toString().indexOf("|" + key.toString()) > -1)
            )).pipe(
                map((event: BroadcastEvent) => <T>event.data));
    }
}

export class GlobalEvents {
    static readonly candidateSummaryLoaded = "CandidateSummaryLoaded";
    static readonly candidateWasChanged = "CandidateWasChanged";
    static readonly currentUserChanged = "CurrentUserChanged";
}
