export class HtmlHelper {

    static decodeHtmlEntity(str: string): string {
        return str.replace(/&#(\d+);/g, (match, dec) => {
            return String.fromCharCode(dec);
        });
    };

    static encodeHtmlEntity(str: string): string {
        return str.split("").map(x => '&#' + x.charCodeAt(0) + ';').join("");
    };
}