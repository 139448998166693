import { Injectable } from "@angular/core";

import { HttpHelper } from "hro-helpers";
import { CurrentUserModel } from "../models/current-user.model";
import { BehaviorSubject, Observable, Observer } from "rxjs";
import { ApiService } from "./api.service";
import { ChangePasswordModel } from "../pages/password/change-password/change-password.model";
import { UserProfileModel } from "../pages/profile/user-profile.model";

@Injectable()
export class UserService {

  private userSubject: BehaviorSubject<CurrentUserModel | null>;
  public user: Observable<CurrentUserModel | null>;

  constructor(readonly httpHelper: HttpHelper, readonly apiService: ApiService) {
    this.userSubject = new BehaviorSubject<CurrentUserModel | null>(null);
    this.user = this.userSubject.asObservable();
  }

  public isUserExists() {
    return this.userSubject.value != null;
  }

  public get userValue(): CurrentUserModel {
    if (this.userSubject.value === null) {
      throw "UserService.userSubject is null";
    }
    return this.userSubject.value;
  }

  public getCurrentUser(): Observable<CurrentUserModel> {
    return Observable.create((observer: Observer<CurrentUserModel>) => {
      this.httpHelper.getObject<CurrentUserModel>(`${this.apiService.usersUrl}/current-user`)
        .subscribe(user => {
          this.userSubject.next(user);
          observer.next(user);
        },
          err => observer.error(err));
    });
  }

  public clearUser() {
    this.userSubject.next(null);
  }

  userHasRight(right: string): boolean {
    return true;
    //if (this.userValue == null)
    //    return false;

    //right = right.toLocaleLowerCase();

    //const userRight = this.userValue.permissions.rights.some((item) => {
    //    return item.toLocaleLowerCase() === right;
    //});

    //if (userRight)
    //    return true;
    //return false;
  }

  changePassword(model: ChangePasswordModel): Observable<void> {
    return this.httpHelper.post(model, this.apiService.usersUrl + "/current-user/change-password");
  }

  resetPassword(password: string): Observable<void> {
    return this.httpHelper.post(password, this.apiService.usersUrl + "/current-user/reset-password");
  }

  getProfile(): Observable<UserProfileModel>{
    return this.httpHelper.get(this.apiService.usersUrl + "/current-user/profile");
  }

  saveProfile(model: UserProfileModel): Observable<void> {
    return this.httpHelper.post(model, this.apiService.usersUrl + "/current-user/profile");
  }

  //userHasRole(role: VisUserRoleEnum): boolean {
  //      if (this.userValue == null)
  //          return false;

  //      return this.userValue.roleId === role;
  //  }
}
